<template>
  <div class="container-fluid">
    <h4>เกณฑ์การประเมินความเสี่ยงของการได้รับเชื้อไวรัสโคโรน่า 2019</h4>
    <div align="center">
      <img
        src="https://klongchu.github.io/budhosp-questionnaire-covid19/img/map_covid19.13a86c27.jpg"
        alt=""
        width="400"
        srcset=""
      />
    </div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-list-group>
        <b-list-group-item>
          <b-form-group
            label="1. ผู้ป่วยเดินทางมาจากจังหวัดเสี่ยงตามประกาศของ ศบค."
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="selected1"
              :options="options1"
              :aria-describedby="ariaDescribedby"
              name="radio-options1"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>

        <b-list-group-item v-if="question_1_boolean">
          <b-form-group
            label="2. ความหนาแน่นของสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-2"
              v-model="selected2"
              :options="options2"
              :aria-describedby="ariaDescribedby"
              name="radio-options2"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>
        <b-list-group-item v-if="question_1_boolean">
          <b-form-group
            label="3.	การระบายอากาศของสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-3"
              v-model="selected3"
              :options="options3"
              :aria-describedby="ariaDescribedby"
              name="radio-options3"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>
        <b-list-group-item v-if="question_1_boolean">
          <b-form-group
            label="4.	ระยะเวลาที่ใช้ในสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-4"
              v-model="selected4"
              :options="options4"
              :aria-describedby="ariaDescribedby"
              name="radio-options4"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>
        <b-list-group-item v-if="question_1_boolean">
          <b-form-group
            label="5.	ลักษณะการพูดคุยในสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-5"
              v-model="selected5"
              :options="options5"
              :aria-describedby="ariaDescribedby"
              name="radio-options5"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>

        <b-list-group-item v-if="!question_1_boolean && show_question_6">
          <b-form-group
            label="2. ผู้ใกล้ชิดเดินทางมาจากจังหวัดเสี่ยงตามประกาศ ศบค. ภายใน 14 วัน"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-6"
              v-model="selected6"
              :options="options1"
              :aria-describedby="ariaDescribedby"
              name="radio-options6"
            ></b-form-radio-group>
          </b-form-group>
        </b-list-group-item>
      </b-list-group>

      <br />
      <b-row>
        <b-col>
          <b-button size="lg" block variant="success" type="submit">
            <b-icon icon="box-arrow-in-up-right"></b-icon>ส่งผลตรวจสอบ
          </b-button>
        </b-col>
      </b-row>
      <br />
    </b-form>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      show: true,
      selected1: 5,
      options1: [
        { text: "พื้นที่สีเขียว (ไม่มีผู้ป่วย)", value: 0 },
        { text: "พื้นที่สีเหลือง (1-10 ราย)", value: 1 },
        { text: "พื้นที่สีส้ม (11-50 ราย)", value: 2 },
        { text: "พื้นที่สีแดง (มากกว่า 50 ราย)", value: 3 },
      ],
      selected2: 0,
      options2: [
        { text: "ไม่ได้ไป", value: 0 },
        { text: "ห่างกันมากกว่า 1 เมตร", value: 1 },
        { text: "ห่างกันน้อยกว่า 1 เมตร แต่ไม่เบียดเสียด", value: 2 },
        { text: "เบียดเสียดหรือห่างกันน้อยกว่า 30 ชม", value: 3 },
      ],
      selected3: 0,
      options3: [
        { text: "ไม่ได้ไป", value: 0 },
        { text: "ทำกิจกรรมในที่โล่งไม่มีเครื่องปรับอากาศ", value: 1 },
        {
          text: "สถานที่ปิดติดเครื่องปรับอากาศและมีการระบายอากาศเพียงพอ",
          value: 2,
        },
        {
          text: "สถานที่ปิดติดเครื่องปรับอากาศและไม่มีการระบายอากาศ",
          value: 3,
        },
      ],
      selected4: 0,
      options4: [
        { text: "ไม่ได้ไป", value: 0 },
        { text: "น้อยกว่า 30 นาที", value: 1 },
        { text: "30 นาที - 1 ชั่วโมง", value: 2 },
        { text: "มากกว่า 1 ชั่วโมง", value: 3 },
      ],
      selected5: 0,
      options5: [
        { text: "ไม่ได้ไป", value: 0 },
        { text: "พูดคุยธรรมดา + ใส่ Mask", value: 1 },
        { text: "มีการใช้เสียงดังหรือตะโกน", value: 2 },
        { text: "มีการร้องตะโกนเกือบตลอดเวลา", value: 3 },
      ],
      selected6: 0,
      sum: 0,
      question_1_boolean: false,
      show_question_6:false,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.$swal({
        title: "ยืนยันส่งแบบสอบถาม Covid 19?",
        text: "ยืนยันส่งแบบสอบถาม Covid 19!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.sum = parseInt(
            this.selected1 +
              this.selected2 +
              this.selected3 +
              this.selected4 +
              this.selected5
          );
          switch (true) {
            case this.selected1 == 0 || this.selected1 == 1:
              console.warn(this.selected6);
              //แบบประเมินผู้ใกล้ชิด
              switch (true) {
                case this.selected6 == 0 || this.selected6 == 1:
                  this.$swal({
                    title: "ความเสี่ยงต่ำ",
                    text: "",
                    html:
                      "<p style='text-align: center'>ผู้ใกล้ชิดไม่เสี่ยง ถือว่าผู้ป่วยเสี่ยงต่ำ ให้สังเกตอาการตนเอง 14 วัน สามารถทำงานได้ตามปกติ และยึดหลัก DMHTT</p> <p style='text-align: left'> • D – Distancing - เว้นระยะระหว่างกัน หลีกเลี่ยงการสัมผัสกับผู้อื่น </p><p style='text-align: left'> • M – Mask wearing – สวมหน้ากากอนามัยตลอดเวลา</p><p style='text-align: left'> • H – Hand washing – ล้างมือบ่อย ๆ </p><p style='text-align: left'> • T – Testing – ตรวจวัดอุณหภูมิร่างกาย </p><p style='text-align: left'> • T – Thaichana – ติดตั้งและใช้แอปพลิเคชั่นไทยชนะ</p>",
                    imageUrl:
                      "https://raw.githubusercontent.com/klongchu/budhosp-questionnaire-covid19/main/joy_icon-icons.com_67806.png",
                    imageHeight: 200,
                    imageAlt: "ความเสี่ยงต่ำ",
                  });
                  break;

                default:
                  this.$swal
                    .mixin({
                      input: "text",
                      confirmButtonText: "Next &rarr;",
                      showCancelButton: true,
                      progressSteps: ["1", "2"],
                    })
                    .queue([
                      {
                        title:
                          "ประเมินเฉพาะผู้ใกล้ชิดที่เดินทางมาจากจังหวัดสีส้ม และ แดง",
                        text:
                          "1.	ผู้ใกล้ชิดมีอาการไข้ ไอ เจ็บคอ น้ำมูก จมูกไม่ได้กลิ่น ลิ้นไม่รับรส",
                        input: "radio",
                        inputClass: "swal-select-event",
                        inputPlaceholder: "กรุณาเลือกข้อมูล",
                        inputOptions: {
                          1: "ใช่",
                          2: "ไม่ใช่",
                        },
                        inputValidator: (value) => {
                          if (!value) {
                            return "กรุณาเลือกข้อมูล";
                          }
                        },
                      },
                      {
                        title:
                          "ประเมินเฉพาะผู้ใกล้ชิดที่เดินทางมาจากจังหวัดสีส้ม และ แดง",
                        text:
                          "2.	ใช้พื้นที่ร่วมกัน พูดคุยใกล้ชิด (ใกล้กว่า 1 เมตร) ในห้องปิด และไม่ใส่ mask",
                        input: "radio",
                        inputClass: "swal-select-event",
                        inputPlaceholder: "กรุณาเลือกข้อมูล",
                        inputOptions: {
                          1: "ใช่",
                          2: "ไม่ใช่",
                        },
                        inputValidator: (value) => {
                          if (!value) {
                            return "กรุณาเลือกข้อมูล";
                          }
                        },
                      },
                    ])
                    .then((result) => {
                      if (result.value) {
                        //const answers = JSON.stringify(result.value);
                        console.warn(result.value);
                        var total =
                          parseInt(result.value[0]) + parseInt(result.value[1]);
                        console.warn(total);
                        switch (true) {
                          case total == 4:
                            this.$swal({
                              title: "ความเสี่ยงต่ำ",
                              text: "",
                              html:
                                "<p style='text-align: center'>ผู้ใกล้ชิดไม่เสี่ยง ถือว่าผู้ป่วยเสี่ยงต่ำ ให้สังเกตอาการตนเอง 14 วัน สามารถทำงานได้ตามปกติ และยึดหลัก DMHTT</p> <p style='text-align: left'> • D – Distancing - เว้นระยะระหว่างกัน หลีกเลี่ยงการสัมผัสกับผู้อื่น </p><p style='text-align: left'> • M – Mask wearing – สวมหน้ากากอนามัยตลอดเวลา</p><p style='text-align: left'> • H – Hand washing – ล้างมือบ่อย ๆ </p><p style='text-align: left'> • T – Testing – ตรวจวัดอุณหภูมิร่างกาย </p><p style='text-align: left'> • T – Thaichana – ติดตั้งและใช้แอปพลิเคชั่นไทยชนะ</p>",
                              imageUrl:
                                "https://raw.githubusercontent.com/klongchu/budhosp-questionnaire-covid19/main/joy_icon-icons.com_67806.png",
                              imageHeight: 200,
                              imageAlt: "ความเสี่ยงต่ำ",
                            });
                            break;
                          default:
                            this.$swal({
                              title: "ความเสี่ยงปานกลาง",
                              text: "",
                              html:
                                "<p style='text-align: center'>ความเสี่ยงปานกลาง (Moderate risk) ถ้ามีอาการไข้ ไอ เจ็บคอ มีน้ำมูก ให้ไปรับการ swab ที่โรงพยาบาลใกล้บ้าน เพื่อตรวจหาเชื้อ COVID-19 แต่ถ้าไม่มีอาการดังกล่าว ให้สังเกตอาการตนเอง 14 วัน สามารถทำงานได้ตามปกติ และยึดหลัก DMHTT</p> <p style='text-align: left'> • D – Distancing - เว้นระยะระหว่างกัน หลีกเลี่ยงการสัมผัสกับผู้อื่น </p><p style='text-align: left'> • M – Mask wearing – สวมหน้ากากอนามัยตลอดเวลา</p><p style='text-align: left'> • H – Hand washing – ล้างมือบ่อย ๆ </p><p style='text-align: left'> • T – Testing – ตรวจวัดอุณหภูมิร่างกาย </p><p style='text-align: left'> • T – Thaichana – ติดตั้งและใช้แอปพลิเคชั่นไทยชนะ</p>",
                              imageUrl:
                                "https://raw.githubusercontent.com/klongchu/budhosp-questionnaire-covid19/main/embarrass_icon-icons.com_67803.png",
                              imageHeight: 200,
                              imageAlt: "ความเสี่ยงปานกลาง",
                            });
                        }
                      }
                    });

                  break;
              }

              break;

            default:
              switch (true) {
                // If score is 90 or greater
                case this.sum <= 6:
                  /* inputOptions can be an object or Promise */
                  //ต่ำกว่า 7 คะแนน ให้ไปทำแบบประเมินผู้ใกล้ชิด
                  this.selected1 = 0;
                  this.question_1_boolean = false;
                  this.$swal("ให้ทำแบบประเมินผู้ใกล้ชิด", "ให้ทำแบบประเมินผู้ใกล้ชิด", "warning");
                  console.warn("ssss");
                  break;
                // If score is 80 or greater
                case this.sum >= 7 && this.sum <= 11:
                  //this.$swal("ความเสี่ยงปานกลาง", "ความเสี่ยงปานกลาง", "warning");
                  this.$swal({
                    title: "ความเสี่ยงปานกลาง",
                    text: "",
                    html:
                      "<p style='text-align: center'>ความเสี่ยงปานกลาง (Moderate risk) ถ้ามีอาการไข้ ไอ เจ็บคอ มีน้ำมูก ให้ไปรับการ swab ที่โรงพยาบาลใกล้บ้าน เพื่อตรวจหาเชื้อ COVID-19 แต่ถ้าไม่มีอาการดังกล่าว ให้สังเกตอาการตนเอง 14 วัน สามารถทำงานได้ตามปกติ และยึดหลัก DMHTT</p> <p style='text-align: left'> • D – Distancing - เว้นระยะระหว่างกัน หลีกเลี่ยงการสัมผัสกับผู้อื่น </p><p style='text-align: left'> • M – Mask wearing – สวมหน้ากากอนามัยตลอดเวลา</p><p style='text-align: left'> • H – Hand washing – ล้างมือบ่อย ๆ </p><p style='text-align: left'> • T – Testing – ตรวจวัดอุณหภูมิร่างกาย </p><p style='text-align: left'> • T – Thaichana – ติดตั้งและใช้แอปพลิเคชั่นไทยชนะ</p>",
                    imageUrl:
                      "https://raw.githubusercontent.com/klongchu/budhosp-questionnaire-covid19/main/embarrass_icon-icons.com_67803.png",
                    imageHeight: 200,
                    imageAlt: "ความเสี่ยงปานกลาง",
                  });
                  break;
                // If score is 70 or greater
                case this.sum > 11:
                  this.$swal({
                    title: "ความเสี่ยงสูง",
                    text: "",
                    html:
                      "<p style='text-align: center'>ให้ไปรับการ swab ที่โรงพยาบาลใกล้บ้าน เพื่อตรวจหาเชื้อ COVID-19 กักตัวอยู่บ้าน 10 วัน และ swab ซ้ำในวันที่ 5</p>",
                    imageUrl:
                      "https://raw.githubusercontent.com/klongchu/budhosp-questionnaire-covid19/main/sad_icon-icons.com_67809.png",
                    imageHeight: 200,
                    imageAlt: "ความเสี่ยงสูง",
                  });
                  break;
                default:
                  console.log("F");
              }
              break;
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault(); // Reset our form values
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  watch: {
    selected1: function (val) {
      switch (true) {
        case val === 0 || val === 1:
          this.question_1_boolean = false;
          this.show_question_6 = true;
          console.warn(val);
          break;
        default:
          this.question_1_boolean = true;
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
