var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('h4',[_vm._v("เกณฑ์การประเมินความเสี่ยงของการได้รับเชื้อไวรัสโคโรน่า 2019")]),_vm._m(0),(_vm.show)?_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-list-group',[_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"1. ผู้ป่วยเดินทางมาจากจังหวัดเสี่ยงตามประกาศของ ศบค."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-1","options":_vm.options1,"aria-describedby":ariaDescribedby,"name":"radio-options1"},model:{value:(_vm.selected1),callback:function ($$v) {_vm.selected1=$$v},expression:"selected1"}})]}}],null,false,2106511437)})],1),(_vm.question_1_boolean)?_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"2. ความหนาแน่นของสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-2","options":_vm.options2,"aria-describedby":ariaDescribedby,"name":"radio-options2"},model:{value:(_vm.selected2),callback:function ($$v) {_vm.selected2=$$v},expression:"selected2"}})]}}],null,false,544995437)})],1):_vm._e(),(_vm.question_1_boolean)?_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"3.\tการระบายอากาศของสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-3","options":_vm.options3,"aria-describedby":ariaDescribedby,"name":"radio-options3"},model:{value:(_vm.selected3),callback:function ($$v) {_vm.selected3=$$v},expression:"selected3"}})]}}],null,false,3186127885)})],1):_vm._e(),(_vm.question_1_boolean)?_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"4.\tระยะเวลาที่ใช้ในสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-4","options":_vm.options4,"aria-describedby":ariaDescribedby,"name":"radio-options4"},model:{value:(_vm.selected4),callback:function ($$v) {_vm.selected4=$$v},expression:"selected4"}})]}}],null,false,3714260269)})],1):_vm._e(),(_vm.question_1_boolean)?_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"5.\tลักษณะการพูดคุยในสถานที่เสี่ยง (กรณีมาจากจังหวัดแดง ส้ม)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-5","options":_vm.options5,"aria-describedby":ariaDescribedby,"name":"radio-options5"},model:{value:(_vm.selected5),callback:function ($$v) {_vm.selected5=$$v},expression:"selected5"}})]}}],null,false,2977232077)})],1):_vm._e(),(!_vm.question_1_boolean && _vm.show_question_6)?_c('b-list-group-item',[_c('b-form-group',{attrs:{"label":"2. ผู้ใกล้ชิดเดินทางมาจากจังหวัดเสี่ยงตามประกาศ ศบค. ภายใน 14 วัน"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-group-6","options":_vm.options1,"aria-describedby":ariaDescribedby,"name":"radio-options6"},model:{value:(_vm.selected6),callback:function ($$v) {_vm.selected6=$$v},expression:"selected6"}})]}}],null,false,2707053290)})],1):_vm._e()],1),_c('br'),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"size":"lg","block":"","variant":"success","type":"submit"}},[_c('b-icon',{attrs:{"icon":"box-arrow-in-up-right"}}),_vm._v("ส่งผลตรวจสอบ ")],1)],1)],1),_c('br')],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":"https://klongchu.github.io/budhosp-questionnaire-covid19/img/map_covid19.13a86c27.jpg","alt":"","width":"400","srcset":""}})])}]

export { render, staticRenderFns }